// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/_layout/_common/_includes/Header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/_layout/_common/_includes/Header.tsx");
  import.meta.hot.lastModified = "1732696948000";
}
// REMIX HMR END

import { useLocation } from '@remix-run/react';
import { AnchorLink } from '~/components/AnchorLink';
import { Button } from '~/components/ui/button';
import { AUTH_PAGES_URL, CONTENT_PAGES, SYSTEM_USER_TYPES } from '~/lib/constants';
import { USER_GUIDE, FAQ } from '~/lib/lang/en';
import { useUser } from '~/_hooks/useApp';
import UserMenu from './UserMenu';
const Header = () => {
  _s();
  const location = useLocation();
  const {
    isLoggedIn,
    user
  } = useUser();
  return <header className='bg-white/80 sticky top-0 backdrop-blur-md z-50 shadow-sm py-1'>
      <div className='mx-auto flex container items-center gap-4 md:gap-8 px-4 justify-between w-full relative h-[4.5rem]'>
        <div className='flex flex-wrap justify-start align-middle items-center shrink-0'>
          <div className='w-24 md:w-32 inline-flex top-0 left-0 right-0 mx-auto'>
            <AnchorLink className='block text-primary-500 py-2' to={isLoggedIn ? '/app' : '/'}>
              <img src='/assets/newlogo.png' alt='SAM Corporate' className='w-24 h-14 md:w-32 mx-auto' />
            </AnchorLink>
          </div>
        </div>
        <div className='flex space-x-4 justify-between align-middle items-center shrink-0'>
          {!location.pathname.includes('app') && isLoggedIn && user.type === SYSTEM_USER_TYPES.FE_USER && <AnchorLink to={`/app`} title='Workspaces'>
                Workspaces
              </AnchorLink>}

          {isLoggedIn && user.type === SYSTEM_USER_TYPES.BE_USER && <AnchorLink to={`/admin`} title='Admin'>
              Admin
            </AnchorLink>}
          <AnchorLink to={CONTENT_PAGES.USER_GUIDE} title={USER_GUIDE}>
            {USER_GUIDE}
          </AnchorLink>
          <AnchorLink to={CONTENT_PAGES.USER_GUIDE} title={FAQ}>
            {FAQ}
          </AnchorLink>
          {isLoggedIn && <UserMenu layoutType='public' />}

          {!isLoggedIn && !location.pathname.includes('login') && <Button asChild>
              <AnchorLink to={AUTH_PAGES_URL.LOGIN}>Login</AnchorLink>
            </Button>}
        </div>
      </div>
    </header>;
};
_s(Header, "5NxcbcQS5jzesASqbenRYtbF+sQ=", false, function () {
  return [useLocation, useUser];
});
_c = Header;
export default Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;